@design-tokens url('../../utils/media-queries.json') format('style-dictionary3');

.richText {
  font-size: var(--font-size-m);
  color: var(--color-grey-1);
  line-height: var(--line-height-l);

  @media (min-width: design-token("--viewport-l")) {
    font-size: var(--font-size-l);
  }

  & strong,
  & b {
    font-weight: var(--font-weight-bold);
  }

  & a {
    color: var(--color-grey-1);
    text-decoration: underline;
    transition: color var(--transition);

    &:hover,
    &:focus {
      color: var(--color-cyan);
    }
  }

  & p,
  & ul,
  & ol,
  & li,
  & h1,
  & h2,
  & h3 {
    margin: 0;
  }

  & p:not(:last-child),
  & ul:not(:last-child),
  & ol:not(:last-child) {
    margin: 0 0 calc(var(--font-size-m) * 1.5);

    @media (min-width: design-token("--viewport-l")) {
      margin: 0 0 calc(var(--font-size-l) * 1.5);
    }
  }

  & li:not(:last-child) {
    margin: 0 0 calc(var(--font-size-m) * 0.25);

    @media (min-width: design-token("--viewport-l")) {
      margin: 0 0 calc(var(--font-size-l) * 0.25);
    }
  }

  & h1,
  & h2,
  & h3 {
    color: currentColor;
    font-family: var(--font-family-condensed);
    line-height: 1;
  }

  & h1 {
    font-size: var(--font-size-xxxl);
    margin: 0;
    padding-top: 22px;
    position: relative;

    @media (min-width: design-token("--viewport-l")) {
      font-size: var(--font-size-xxxxxl);
      padding-top: 28px;
    }

    &:not(:first-child) {
      margin: calc(var(--font-size-xxxl) * 1.5) 0
        calc(var(--font-size-xxxl) * 0.5);

      @media (min-width: design-token("--viewport-l")) {
        margin: calc(var(--font-size-xxxxxl) * 1.5) 0
          calc(var(--font-size-xxxxxl) * 0.5);
      }
    }

    &::before {
      content: "";
      background-color: var(--color-green);
      border-radius: var(--border-radius);
      height: 4px;
      left: 0;
      position: absolute;
      top: 0;
      width: 50px;

      @media (min-width: design-token("--viewport-l")) {
        height: 6px;
        width: 80px;
      }
    }
  }

  & h2 {
    font-size: var(--font-size-xxl);
    margin: 0 0 calc(var(--font-size-xxl) * 0.5);
    padding-top: 22px;
    position: relative;

    @media (min-width: design-token("--viewport-l")) {
      font-size: var(--font-size-xxxl);
      margin: 0 0 calc(var(--font-size-xxxl) * 0.5);
      padding-top: 28px;
    }

    &:not(:first-child) {
      margin: calc(var(--font-size-xxl) * 1.5) 0
        calc(var(--font-size-xxl) * 0.5);

      @media (min-width: design-token("--viewport-l")) {
        margin: calc(var(--font-size-xxxl) * 1.5) 0
          calc(var(--font-size-xxxl) * 0.5);
      }
    }

    &::before {
      content: "";
      background-color: var(--color-cyan);
      border-radius: var(--border-radius);
      height: 4px;
      left: 0;
      position: absolute;
      top: 0;
      width: 50px;

      @media (min-width: design-token("--viewport-l")) {
        height: 6px;
        width: 80px;
      }
    }
  }

  & h3 {
    font-size: var(--font-size-xl);
    margin: 0 0 calc(var(--font-size-xl) * 0.5);

    @media (min-width: design-token("--viewport-l")) {
      font-size: var(--font-size-xxl);
      margin: 0 0 calc(var(--font-size-xxl) * 0.5);
    }

    &:not(:first-child) {
      margin: calc(var(--font-size-xl) * 1.5) 0 calc(var(--font-size-xl) * 0.5);

      @media (min-width: design-token("--viewport-l")) {
        margin: calc(var(--font-size-xxl) * 1.5) 0
          calc(var(--font-size-xxl) * 0.5);
      }
    }
  }

  & ul {
    padding: 0;
  }

  & ol {
    padding: 0 0 0 20px;
  }

  & ul {
    list-style: none;

    & li {
      position: relative;
      padding-left: 18px;

      @media (min-width: design-token("--viewport-l")) {
        padding-left: 26px;
      }

      &::before {
        background-color: var(--color-cyan);
        border-radius: 50%;
        content: "";
        display: inline-block;
        font-weight: bold;
        height: 6px;
        left: 0;
        position: absolute;
        top: 12px;
        width: 6px;

        @media (min-width: design-token("--viewport-l")) {
          top: 13px;
          height: 10px;
          width: 10px;
        }
      }
    }
  }
}

.richText.small {
  font-size: var(--font-size-s);

  @media (min-width: design-token("--viewport-l")) {
    font-size: var(--font-size-m);
  }

  & h3 {
    font-size: var(--font-size-l);
    margin: 0 0 calc(var(--font-size-l) * 0.5);

    @media (min-width: design-token("--viewport-l")) {
      font-size: var(--font-size-xl);
      margin: 0 0 calc(var(--font-size-xl) * 0.5);
    }

    &:not(:first-child) {
      margin: calc(var(--font-size-l) * 1.5) 0 calc(var(--font-size-l) * 0.5);

      @media (min-width: design-token("--viewport-l")) {
        margin: calc(var(--font-size-xl) * 1.5) 0
          calc(var(--font-size-xl) * 0.5);
      }
    }
  }

  & p:not(:last-child),
  & ul:not(:last-child),
  & ol:not(:last-child) {
    margin: 0 0 calc(var(--font-size-s) * 1.5);

    @media (min-width: design-token("--viewport-l")) {
      margin: 0 0 calc(var(--font-size-m) * 1.5);
    }
  }

  & li:not(:last-child) {
    margin: 0 0 calc(var(--font-size-s) * 0.25);

    @media (min-width: design-token("--viewport-l")) {
      margin: 0 0 calc(var(--font-size-m) * 0.25);
    }
  }
}

.richText.white {
  color: var(--color-white);

  & a {
    color: var(--color-white);
  }

  & h1::before,
  & h2::before {
    background-color: var(--color-yellow);
  }
}
