@design-tokens url('../utils/media-queries.json') format('style-dictionary3');

.container {
  align-items: center;
  background-color: var(--color-white);
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  left: 0;
  padding: 8px 20px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: var(--z-index-navigation);

  @media (min-width: design-token("--viewport-m")) {
    padding: 8px 32px;
  }

  @media (min-width: design-token("--viewport-l")) {
    padding: 0 40px;
    height: 80px;
  }
}

.logo {
  height: 32px;

  @media (min-width: design-token("--viewport-m")) {
    height: 40px;
  }

  & svg {
    height: 32px;
    width: 141px;

    @media (min-width: design-token("--viewport-m")) {
      height: 40px;
      width: 177px;
    }
  }
}

.menuButton {
  align-items: center;
  background-color: transparent;
  border: 0;
  color: var(--color-grey-1);
  cursor: pointer;
  display: flex;
  font-family: inherit;
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-medium);
  line-height: 1;
  margin: 0;
  outline: 0;
  padding: 20px 0 20px 20px;

  & svg {
    height: 20px;
    margin-left: 8px;
    width: 20px;
  }

  @media (min-width: design-token("--viewport-m")) {
    font-size: var(--font-size-m);
  }

  @media (min-width: design-token("--viewport-l")) {
    display: none;
  }
}

.navItemsContainer {
  background-color: var(--color-green);
  display: none;
  height: 100%;
  height: calc(100vh - 72px);
  left: 0;
  overflow: scroll;
  padding: 12px 20px 20px;
  position: absolute;
  scrollbar-width: none;
  top: 72px;
  width: 100%;

  &.isOpen {
    display: block;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: design-token("--viewport-m")) {
    height: calc(100vh - 77px);
    padding: 20px 32px 32px;
    top: 77px;
  }

  @media (min-width: design-token("--viewport-l")) {
    background-color: transparent;
    display: flex;
    height: 100%;
    overflow: visible;
    padding: 0;
    position: relative;
    top: 0;
    width: auto;
  }
}

.navItemGroup {
  border-bottom: solid 1px rgba(255, 255, 255, 0.3);
  padding: 20px 0;

  &:last-child {
    margin: 0 0 60px;
  }

  @media (min-width: design-token("--viewport-l")) {
    border-bottom: none;
    padding: 0;
    position: relative;

    &:last-child {
      margin: 0;
    }

    &:hover .subNavItemsContainer,
    &:focus .subNavItemsContainer {
      display: block;
    }
  }
}

.navItem {
  align-items: center;
  color: var(--color-white);
  display: flex;
  font-family: var(--font-family-compressed);
  font-size: var(--font-size-xxl);
  font-weight: var(--font-weight-bold);
  line-height: 1;
  text-decoration: none;

  &.isActive {
    text-decoration: underline;
  }

  & svg {
    fill: var(--color-yellow);
    height: 16px;
    margin: 0 12px 0 0;
    transform: rotate(-90deg);
    width: 16px;
  }

  @media (min-width: design-token("--viewport-l")) {
    color: var(--color-grey-1);
    flex-direction: row-reverse;
    font-family: var(--font-family);
    font-size: var(--font-size-m);
    font-weight: var(--font-weight-medium);
    height: 100%;
    line-height: 1;
    padding: 0 16px;
    position: relative;

    &::after {
      content: "";
      background-color: transparent;
      border-radius: 3px 3px 0 0;
      bottom: 0;
      display: block;
      height: 5px;
      left: 0;
      position: absolute;
      right: 0;
      transition: background-color var(--transition);
      width: 100%;
    }

    & svg {
      fill: var(--color-grey-1);
      height: 10px;
      margin: 0 0 0 8px;
      transform: none;
      width: 10px;
    }

    &:not(.hasSub) svg {
      display: none;
    }

    &:hover,
    &:focus {
      &::after {
        background-color: var(--color-grey-5);
      }
    }

    &.isActive {
      text-decoration: none;

      &::after {
        background-color: var(--color-green);
      }
    }
  }
}

.subNavItemsContainer {
  margin-top: 16px;
  padding: 0 28px;

  @media (min-width: design-token("--viewport-l")) {
    display: none;
    bottom: 0;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
    transform: translateY(calc(100% + 1px));

    &::before {
      content: "";
      height: calc(100% + 50px);
      left: 50%;
      opacity: 0.4;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: calc(100% + 50px);
      z-index: -1;
    }
  }
}

.subNavItem {
  color: var(--color-white);
  display: block;
  font-family: var(--font-family-condensed);
  font-size: var(--font-size-l);
  font-weight: var(--font-weight-medium);
  line-height: 1;
  text-decoration: none;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &.isActive {
    font-weight: var(--font-weight-medium);
    text-decoration: underline;
  }

  @media (min-width: design-token("--viewport-l")) {
    background-color: var(--color-white);
    border-bottom: solid 1px var(--color-grey-4);
    border-left: solid 1px var(--color-grey-4);
    border-right: solid 1px var(--color-grey-4);
    color: var(--color-grey-1);
    font-family: var(--font-family);
    font-size: var(--font-size-s);
    font-weight: var(--font-weight-regular);
    line-height: 1;
    padding: 16px;
    transition: background-color var(--transition);
    width: 280px;

    &:not(:last-child) {
      margin-bottom: 0;
    }

    &:last-child {
      border-bottom-left-radius: var(--border-radius-l);
      border-bottom-right-radius: var(--border-radius-l);
    }

    &:hover,
    &:focus {
      background-color: var(--color-grey-6);
    }

    &.isActive {
      color: var(--color-green);
      text-decoration: none;
    }
  }
}
