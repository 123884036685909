@design-tokens url('../utils/media-queries.json') format('style-dictionary3');

.container {
  margin: 0 auto;
  max-width: 800px;
  padding: 0 20px;
  width: 100%;

  @media (min-width: design-token("--viewport-m")) {
    max-width: 824px;
    padding: 0 32px;
  }

  @media (min-width: design-token("--viewport-l")) {
    max-width: 840px;
    padding: 0 40px;
  }
}

.small {
  max-width: 600px;

  @media (min-width: design-token("--viewport-m")) {
    max-width: 624px;
  }

  @media (min-width: design-token("--viewport-l")) {
    max-width: 640px;
  }
}

.large {
  max-width: 1000px;

  @media (min-width: design-token("--viewport-m")) {
    max-width: 1024px;
  }

  @media (min-width: design-token("--viewport-l")) {
    max-width: 1040px;
  }
}

.extraLarge {
  max-width: 1200px;

  @media (min-width: design-token("--viewport-m")) {
    max-width: 1224px;
  }

  @media (min-width: design-token("--viewport-l")) {
    max-width: 1240px;
  }
}
