@design-tokens url('../utils/media-queries.json') format('style-dictionary3');

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background-color: var(--color-white);
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.25);
  color: var(--color-grey-1);
  padding: 16px 20px;

  @media (min-width: design-token('--viewport-m')) {
    align-items: center;
    flex-direction: row;
    border-radius: var(--border-radius-l);
    padding: 20px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: design-token('--viewport-m')) {
    align-items: center;
    flex-direction: row;
  }
}

.emoji {
  font-size: 24px;
  line-height: 1;
  margin: 0 0 8px;

  @media (min-width: design-token('--viewport-m')) {
    margin: 0 24px 0 0;
  }
}

.text {
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-m);

  & a {
    color: var(--color-grey-1);
  }
}

.buttonContainer {
  margin: 16px 0 0;

  @media (min-width: design-token('--viewport-m')) {
    margin: 0 0 0 24px;
  }
}
