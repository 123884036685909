.button {
  align-items: center;
  appearance: none;
  border-radius: 100px;
  border: 0;
  cursor: pointer;
  display: inline-flex;
  font-family: var(--font-family-condensed);
  font-weight: var(--font-weight-bold);
  line-height: 1;
  text-decoration: none;
  transition: background-color var(--transition), border-color var(--transition);
  text-align: center;

  &:disabled {
    opacity: 0.5;
  }

  &.cyan {
    background-color: var(--color-cyan);
    color: var(--color-white);

    &:hover {
      background-color: var(--color-cyan-hover);
    }
  }

  &.green {
    background-color: var(--color-green);
    color: var(--color-white);

    &:hover {
      background-color: var(--color-green-hover);
    }
  }

  &.grey {
    background-color: var(--color-grey-6);
    color: var(--color-grey-1);
    font-weight: var(--font-weight-medium);

    &:hover {
      background-color: var(--color-grey-5);
    }
  }

  &.greyBorder {
    background-color: var(--color-grey-6);
    border: solid 2px var(--color-grey-4);
    color: var(--color-grey-1);
    font-weight: var(--font-weight-medium);

    &:hover {
      background-color: var(--color-grey-6);
      border-color: var(--color-grey-3);
    }
  }

  &.small {
    font-size: var(--font-size-m);
    padding: 12px 20px;
  }

  &.medium {
    font-size: var(--font-size-l);
    padding: 16px 28px;
  }
}
