@design-tokens url('../utils/media-queries.json') format('style-dictionary3');

.container {
  margin: 48px 0;

  @media (min-width: design-token("--viewport-l")) {
    margin: 60px 0;
  }
}

.container:global(.card_with_image) {
  & + :global(.card_with_image) {
    margin-top: -32px !important;

    @media (min-width: design-token("--viewport-l")) {
      margin-top: -40px !important;
    }
  }
}

.container:global(.image) {
  & + :global(.image) {
    margin-top: -48px !important;

    @media (min-width: design-token("--viewport-l")) {
      margin-top: -60px !important;
    }
  }
}

.container:global(.checklist) {
  & + :global(.image_with_content) {
    margin-top: -64px !important;

    @media (min-width: design-token("--viewport-m")) {
      margin: 80px 0 !important;
    }

    @media (min-width: design-token("--viewport-l")) {
      margin: 120px 0 !important;
    }
  }
}

.container:global(.rich_text) {
  & + :global(.collapsible) {
    margin-top: -32px;

    @media (min-width: design-token("--viewport-l")) {
      margin-top: -40px;
    }
  }
}

.small {
  margin: 32px 0;

  @media (min-width: design-token("--viewport-l")) {
    margin: 40px 0;
  }
}

.large {
  margin: 64px 0;

  @media (min-width: design-token("--viewport-l")) {
    margin: 80px 0;
  }
}

.extralarge {
  margin: 64px 0;

  @media (min-width: design-token("--viewport-m")) {
    margin: 80px 0;
  }

  @media (min-width: design-token("--viewport-l")) {
    margin: 120px 0;
  }
}

.onlyBottom {
  margin-top: 0 !important;

  @media (min-width: design-token("--viewport-m")) {
    margin-top: 0 !important;
  }

  @media (min-width: design-token("--viewport-l")) {
    margin-top: 0 !important;
  }
}
