@design-tokens url('../utils/media-queries.json') format('style-dictionary3');

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.children {
  flex: 1;
}

.green {
  background-color: var(--color-green);
}

.cyan {
  background-color: var(--color-cyan);
}

.messageBarContainer {
  bottom: 0;
  left: 50%;
  max-width: 880px;
  padding: 0;
  position: fixed;
  transform: translateX(-50%);
  width: 100%;
  z-index: var(--z-index-messageBar);

  @media (min-width: design-token("--viewport-m")) {
    padding: 0 20px 40px;
  }

  & > *:not(:last-child) {
    margin-bottom: 16px;
  }
}
