@design-tokens url('../utils/media-queries.json') format('style-dictionary3');

.container {
  background-color: var(--color-grey-6);
  padding: 64px 0 40px;

  @media (min-width: design-token("--viewport-l")) {
    padding: 80px 0 40px;
  }
}

.isLight {
  background-color: var(--color-white);
}

.contentContainer {
  & > * {
    width: 100%;
  }

  @media (min-width: design-token("--viewport-m")) {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    & > *:nth-child(2),
    & > *:nth-child(3) {
      width: 50%;
    }
  }

  @media (min-width: design-token("--viewport-l")) {
    & > *,
    & > *:nth-child(2),
    & > *:nth-child(3) {
      width: calc(25% - 20px);
    }
  }
}

.logoContainer {
  margin: 0 0 48px;

  & svg {
    height: 32px;
    width: 141px;

    @media (min-width: design-token("--viewport-m")) {
      height: 40px;
      width: 177px;
    }
  }

  @media (min-width: design-token("--viewport-l")) {
    margin: 0;
  }
}

.socialContainer {
  align-items: center;
  display: flex;
  margin: 48px 0 0;

  @media (min-width: design-token("--viewport-l")) {
    margin: 0;
  }
}

.title {
  color: var(--color-grey-2);
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-m);
  margin: 0 0 16px;
  text-transform: uppercase;
}

.list {
  margin: 0 0 40px;
  padding: 0;
  list-style-type: none;

  & > li:not(:last-child) {
    margin: 0 0 16px;
  }

  & li:empty {
    height: var(--font-size-l);
  }

  @media (min-width: design-token("--viewport-m")) {
    margin: 0;

    &:not(:last-child) {
      margin: 0 0 58px;
    }
  }
}

.link {
  color: var(--color-grey-2);
  font-size: var(--font-size-m);
  line-height: 1;
  transition: color var(--transition);
  text-decoration: none;

  &:hover,
  &:focus {
    color: var(--color-cyan);
  }
}

.socialIcon {
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 40px;
  justify-content: center;
  position: relative;
  width: 40px;

  &::before {
    content: "";
    background-color: black;
    border-radius: 50%;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity var(--transition);
    width: 100%;
    z-index: 1;
  }

  & svg {
    fill: var(--color-white);
    height: 20px;
    width: 20px;
    position: relative;
    z-index: 2;
  }

  &:not(:last-child) {
    margin: 0 12px 0 0;
  }

  &:hover::before,
  &:focus::before {
    opacity: 0.1;
  }
}

.footer {
  border-top: solid 1px var(--color-grey-4);
  color: var(--color-grey-2);
  font-size: var(--font-size-s);
  line-height: var(--line-height-m);
  margin: 64px 0 0;
  padding-top: 40px;

  & a {
    color: var(--color-grey-2);
    transition: color var(--transition);
    text-decoration: none;

    &:hover,
    &:focus {
      color: var(--color-cyan);
    }
  }

  & > *:not(:last-child) {
    margin: 0 0 12px;
  }

  @media (min-width: design-token("--viewport-footer-m")) {
    align-items: center;
    display: flex;

    & > *:not(:last-child) {
      margin: 0 12px 0 0;
    }
  }

  @media (min-width: design-token("--viewport-l")) {
    margin: 80px 0 0;
  }
}

a.eightLogoContainer {
  display: flex;
  flex-direction: column;

  @media (min-width: design-token("--viewport-footer-m")) {
    align-items: center;
    flex-direction: row;
    margin-left: auto;
  }

  &:hover,
  &:focus {
    color: var(--color-grey-1);

    & .eightLogo svg path:first-child {
      fill: #222;
    }

    & .eightLogo svg path:last-child {
      fill: #f43545;
    }
  }
}

.eightLogo {
  display: block;
  margin: 16px 0 0;

  @media (min-width: design-token("--viewport-footer-m")) {
    margin: 0 0 0 12px;
  }

  & svg {
    display: block;
    height: 24px;
    width: 77px;

    & path {
      fill: #58595b;
      transition: fill var(--transition);
    }
  }
}
